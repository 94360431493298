<template>
  <div>
    <el-form :model="queryParams" ref="form" :inline="true" class="demo-form-inline">
      <el-row>
        <el-row class="custom--top-btn-wrapper" type="flex" :gutter="20">
          <el-col class="left-btn-group" :span="12">
            <el-tooltip placement="top">
              <div slot="content">
                <p class="receive-font">销售配置名称：{{receiveNumber.configName}}</p>
                <p class="receive-font">销售配置上限：{{receiveNumber.configSize}}</p>
                <p class="receive-font">按钮领取上限：{{ receiveNumber.receiveSize }}</p>
                <p class="receive-font">当前可领取上限：{{ receiveNumber.nowSize }}</p>
              </div>
              <GgdCard class="custom--GgdCard" background="#409EFF" title="已领取/最大上限"
                       :content=" receiveStatus.receivedSize + '/' + receiveStatus.receiveSize"
                       unit="条"></GgdCard>
            </el-tooltip>

            <GgdCard class="custom--GgdCard" background="#F19149" title="领取等待"
                     :content="waitTimeStr"></GgdCard>
          </el-col>
          <el-col class="right-btn-group" :offset="1" :span="12">
            <el-button-group>
              <el-button type="primary" size="large"
                         @click="toHome()"
                         :style="{margin:' 5px ',background:'red',
                     borderColor:'red',height:'62px',
                     borderRadius:'10px'}">
                <i class="el-icon-switch-button" :style="{fontSize:'22px'}"></i><br>
                回到主页
              </el-button>
              <el-button type="primary" size="large"
                         @click="next"
                         :style="{margin:' 5px ',background:'#6D88FD',borderColor:'#6D88FD',height:'62px',borderRadius:'10px'}">
                <span style="font-size: 23px;color: #ffe554">点击刷新</span><br>
                <span>剩余机会:{{ stockTotal }}</span>
              </el-button>
            </el-button-group>
          </el-col>
        </el-row>
        <el-row>
          <el-form-item label="成单时间" prop="dealTimeArr" size="mini">
            <el-date-picker
                v-model="dealTimeArr"
                type="datetimerange"
                align="right"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd"
                format="yyyy-MM-dd">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="入库时间" prop="createTimeArr" size="mini">
            <el-date-picker
                v-model="createTimeArr"
                type="datetimerange"
                align="right"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd"
                format="yyyy-MM-dd">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="唤醒时间" prop="wakeTimeArr" size="mini">
            <el-date-picker
                v-model="wakeTimeArr"
                type="datetimerange"
                align="right"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd"
                format="yyyy-MM-dd">
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="doSearch">查询</el-button>
            <el-button type="info" @click="restart">重置</el-button>
          </el-form-item>
        </el-row>
        <el-row>
          <el-table :data="stockTableData" stripe height="680" style="width: 100%" v-loading="loading">
            <template v-slot:empty>当前暂无可领取数据，如存在剩余机会，请点击刷新</template>
            <el-table-column prop="id" align="center" label="机会ID"></el-table-column>
            <el-table-column prop="telephone" align="center" label="机会号码"></el-table-column>
            <!--        <el-table-column prop="subjectName" align="center" label="项目类别"></el-table-column>-->
            <el-table-column prop="dealTime" align="center" label="最新成单时间"></el-table-column>
            <el-table-column prop="bsT" align="center" label="最新回库时间"></el-table-column>
            <el-table-column prop="createTime" align="center" label="首次入库时间"></el-table-column>
            <el-table-column prop="wakeTime" align="center" label="唤醒时间"></el-table-column>
            <el-table-column align="center" prop="name" label="操作" fixed="right" width="330">
              <template slot-scope="scope" v-if="!loading">
                <el-button type="primary" size="mini" @click="showTelephone(scope.row.id)" v-if="receive.phone">
                  查看手机
                </el-button>
                <el-tooltip class="item" effect="dark" content="直呼拨打" placement="top"
                            v-if="callPanshi.qrcode === 'ON' && receive.calls">
                  <el-button v-if="callPanshi.qrcode === 'ON'" type="success" size="mini"
                             @click="calling($event,scope.row.id)">直呼
                  </el-button>
                </el-tooltip>
                <el-button hidden type="danger" size="mini" @click="hangup($event)">挂断</el-button>
                <el-button hidden type="warning" size="mini" @click="haveCalling($event,scope.row.id)">已拨打
                </el-button>
                <el-tooltip class="item" effect="dark" content="双呼拨打" placement="top"
                            v-if="callDouble.qrcode === 'ON' && receive.calls">
                  <el-button v-if="callDouble.qrcode === 'ON'" type="success" size="mini"
                             @click="callDoubleing($event,scope.row.id)">双呼
                  </el-button>
                </el-tooltip>
                <el-button type="primary" size="mini" @click="receiveOppor(scope.row.id, true)"
                           v-if="receive.receive">
                  {{ isShowGetBtn(scope.row.id) ? '领取' : '已领取' }}
                </el-button>

                <el-popover placement="bottom" width="400" :ref="`popover-${scope.$index}`"
                            trigger="click"
                            v-if="receive.freeze"
                            @show="handlePopoverShow">
                  <el-form ref="frozenFrom" :model="frozenForm">
                    <el-form-item label="">
                      <el-radio-group v-model="frozenForm.time">
                        <el-radio label="7" size="mini" border>7天</el-radio>
                        <el-radio label="30" size="mini" border>一个月</el-radio>
                        <el-radio label="90" size="mini" border>三个月</el-radio>
                        <el-radio label="365" size="mini" border>一年</el-radio>
                      </el-radio-group>
                    </el-form-item>
                    <el-form-item>
                      <el-checkbox-group size="mini" v-model="frozenForm.shortcut"
                                         @change="handleCheckbox">
                        <el-checkbox label="学员已愤怒" border></el-checkbox>
                        <el-checkbox label="再拨打投诉" border></el-checkbox>
                        <el-checkbox label="空号" border></el-checkbox>
                      </el-checkbox-group>
                    </el-form-item>
                    <el-form-item>
                      <el-input type="textarea" maxlength="15" :clearable="true"
                                placeholder="请输入冻结原因"
                                v-model="frozenForm.remark"></el-input>
                    </el-form-item>
                    <el-form-item>
                      <el-button type="primary" size="mini"
                                 @click="handleFrozenFormSubmit(scope,scope.row.id)">提交
                      </el-button>
                      <el-button size="mini" @click="handleFrozenFormClose(scope)">取消
                      </el-button>
                    </el-form-item>
                  </el-form>

                  <el-button :ref="`frozen-${scope.$index}`" class="frozen-button"
                             slot="reference" type="info" size="mini">
                    冻结
                  </el-button>
                </el-popover>
              </template>
            </el-table-column>
          </el-table>
        </el-row>
        <ShowTelephone :opportunity-list="showTelephoneObj.opportunityList"
                       :show-obj="showTelephoneObj.showObj">

        </ShowTelephone>
      </el-row>
    </el-form>

    <el-dialog
        title="领取验证"
        :visible.sync="enableVerifyDialog"
        @closed="closeEnableVerifyDialog"
        :close-on-click-modal="false"
        width="30%">
      <el-row>
        <el-col :span="9">
          <el-input v-model="captchaInput" placeholder="请输入验证码"/>
        </el-col>
        <el-col :span="1">
          <el-divider direction="vertical"/>
        </el-col>
        <el-col :span="7">
          <el-image :src="captchaSrc"></el-image>
        </el-col>
        <el-col :span="1">
          <el-divider direction="vertical"/>
        </el-col>
        <el-col :span="5">
          <el-button type="text" @click="beforeReceive(receiveOpportunityId)">看不清?点我换一张</el-button>
        </el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
    <el-button @click="closeEnableVerifyDialog">取 消</el-button>
    <el-button type="primary" @click="submitEnableVerifyDialog">确 定</el-button>
  </span>
    </el-dialog>
  </div>
</template>

<script>
import * as api from "@/utils/api";
import {getUserId, getUserName} from "@/utils/auth";
import ShowTelephone from "@/views/opportunity/components/ShowTelephone";
import * as receiveApi from '@/api/system/receive'
import GgdCard from "@/components/GgdCard.vue";
import Teacher from "@/views/consultant/Teacher.vue";


export default {
  name: "Stock",
  components: {
    Teacher,
    GgdCard,
    ShowTelephone
  },
  data() {
    return {
      type: 0, //是否走缓存 1代表不走缓存
      nextQuery: {
        btnType: 0
      }, //刷新所带参数
      queryParams: {
        dealTimeStart: '',
        dealTimeEnd: '',
        createTimeStart: '',
        createTimeEnd: '',
        wakeTimeEnd: '',
        wakeTimeStart: '',
        btnType: 0
      },
      dealTimeArr: [],
      createTimeArr: [],
      wakeTimeArr: [],
      receiveStatus: {
        receivedSize: 0,
        receiveSize: 0
      },
      waitTimeStr: '00:00:00',
      waitTime: 0,
      backStoreTimeStr: [],
      showTelephoneObj: {
        opportunityList: [],//查看手机号
        showObj: {
          isShow: false
        }
      },
      stockTableData: [],
      frozenForm: {
        shortcut: [],
        time: "",
        remark: ""
      },
      dayAccount: 0, //今日流水
      newClient: 0,//首咨数
      stockTotal: 0, //库存总量
      loading: true,
      callPanshi: {},//磐石云外呼按钮
      uploadTime: [],
      callDouble: {},//双呼外呼按钮,
      receive: {},
      // 倒计时定时器
      timeSetInterval: null,
      showGetBtn: [],
      enableVerifyDialog: false,
      captchaSrc: '',
      captchaInput: '',
      receiveOpportunityId: '',
      pondId: 1,
      receiveNumber: {
        configName:'',
        configSize: 0,
        receiveSize: 0,
        nowSize: 0,
      }
    }
  },
  watch: {
    waitTime: function (a, b) {
      this.showTime(a);
    }
  },
  beforeDestroy() {
    clearInterval(this.timeSetInterval)
  },
  methods: {
    findReceiveNumber(){
      console.log("打印:1111")
      receiveApi.findReceiveNumber(this.receiveId).then(res=>{
        console.log('findReceiveNumber',res)
        this.receiveNumber = res.data
      })
    },
    restart() {
      this.type = 2;
      this.nextQuery = {};
      this.dealTimeArr = [];
      this.createTimeArr = [];
      this.wakeTimeArr = [];
      // this.queryParams.dealTimeStart = '';
      // this.queryParams.dealTimeEnd = '';
      this.findOppor2Time({});
      // this.findOppor();
    },
    doSearch() {
      this.type = 1;
      this.queryParams.dealTimeStart = this.dealTimeArr[0]
      this.queryParams.dealTimeEnd = this.dealTimeArr[1]
      this.queryParams.createTimeStart = this.createTimeArr[0]
      this.queryParams.createTimeEnd = this.createTimeArr[1]
      this.queryParams.wakeTimeStart = this.wakeTimeArr[0]
      this.queryParams.wakeTimeEnd = this.wakeTimeArr[1]
      this.nextQuery = this.queryParams;
      // 搜索操作按钮的类型为1
      this.queryParams.btnType = 1
      this.findOppor2Time(this.queryParams)
    },
    getReceive() {
      receiveApi.infoReceive(this.receiveId).then(res => {
        this.receive = res.data
      })
    },
    /**
     * 获取配置
     */
    Index() {
      api.index().then(res => {
        console.log(res, '获取配置')
        this.callPanshi = res.data.callPanshi;
        this.callDouble = res.data.callDouble;
      })
    },
    findOppor() {
      if (this.receiveId) {
        this.loading = true
        receiveApi.findOppor(this.receiveId, {"pondId": this.pondId}).then(res => {
          if (res.success) {
            this.stockTableData = res.data.data
            this.stockTotal = res.data.total
            this.loading = false
            if (!this.stockTableData || this.stockTableData.length === 0) {
              this.$message.error("暂无数据, 请等待数据填充!");
            }
          } else {
            this.$message.error(res.msg)
            this.toHome(this.receiveId)
          }
        })
        this.findReceiveNumber()
      } else {
        this.toHome()
      }
    },
    findOppor2Time(params) {
      if (this.receiveId) {
        this.loading = true
        params['pondId'] = this.pondId
        receiveApi.findOpporByTime(this.receiveId, params).then(res => {
          if (res.success) {
            this.stockTableData = res.data.data
            this.stockTotal = res.data.total
            this.loading = false
            // if (!this.stockTableData || this.stockTableData.length === 0) {
            //     this.$message.error("无满足条件的数据！");
            //     this.findOppor();
            // }
          } else {
            if (res.code) {
              // this.$message.error(res.msg)
              this.stockTableData = []
              this.stockTotal = 0;
              this.loading = false
            } else {
              this.$message.error(res.msg)
              this.toHome(this.receiveId)
            }
          }
        })
        this.findReceiveNumber()
      } else {
        this.toHome()
      }
    },
    showTelephone(idArr) {
      api.showTelephone([idArr]).then(res => {
        if (res.success) {
          this.showTelephoneObj.opportunityList = res.data
          this.showTelephoneObj.showObj.isShow = true
        } else {
          this.$message.warning("查看手机号失败")
        }
      }).catch(error => {
        this.$message.error("查看手机号失败");
      })
    },
    zebj() {
      var dataKey = document.cookie.replace(/(?:(?:^|.*;\s*)dataKey\s*\=\s*([^;]*).*$)|^.*$/, "$1");
      if (dataKey === 'zhengerbajing') {
        return true
      }
      return false
    },
    /**
     * 双呼拨打
     */
    callDoubleing(e, id) {
      api.doubleCall(id).then(res => {
        if (res.success) {
          //隐藏当前点击的拨打按钮，显示挂断按钮
          e.target.innerText = '已呼叫'
        } else {
          this.$message.warning(res.msg);
        }
      }).catch(error => {
        this.$message.warning("拨打失败");
      })
    },
    /**
     * 拨打
     */
    calling(e, id) {
      api.calling(id).then(res => {
        if (res.success) {
          //隐藏当前点击的拨打按钮，显示挂断按钮
          let target = e.target
          let tagName = target.tagName
          if (tagName === 'SPAN') {
            target = target.parentNode
          }
          let next = target.nextSibling;
          target.setAttribute("hidden", true)
          next.removeAttribute("hidden")
        } else {
          this.$message.warning(res.msg);
        }
      }).catch(error => {
        this.$message.error("拨打失败");
      })
    },
    /**
     * 挂断
     */
    hangup(e) {
      api.hangUp().then(res => {
        if (res.success) {
          //隐藏当前点击的挂断按钮，显示拨打按钮
          let target = e.target
          let tagName = target.tagName
          if (tagName === 'SPAN') {
            target = target.parentNode
          }
          let next = target.nextSibling;
          target.setAttribute("hidden", true)
          next.removeAttribute("hidden")
        } else {
          this.$message.warning(res.msg);
        }
      }).catch(error => {
        this.$message.error("挂断失败");
      })
    },
    /**
     * 已拨打
     */
    haveCalling(e, id) {
      api.calling(id, 2).then(res => {
        if (res.success) {
          //隐藏当前点击的挂断按钮，显示拨打按钮
          let target = e.target
          let tagName = target.tagName
          if (tagName === 'SPAN') {
            target = target.parentNode
          }
          let prev = target.previousSibling;
          target.setAttribute("hidden", true)
          prev.removeAttribute("hidden")
        } else {
          this.$message.warning(res.msg);
        }
      }).catch(error => {
        this.$message.error("拨打失败");
      })
    },
    showTime(t) {
      if (t > 0) {
        let h = parseInt(t / 3600)
        h = h < 10 ? "0" + h : h;
        let m = parseInt(t / 60 % 60)
        m = m < 10 ? "0" + m : m;
        let s = parseInt(t % 60)
        s = s < 10 ? "0" + s : s;
        this.waitTimeStr = h + ':' + m + ':' + s
      }
    },
    getReceiveSize() {
      receiveApi.getReceiveSize(this.receiveId).then(res => {
        this.receiveStatus = res.data
        let wait = this.receiveStatus.receiveWait
        if (wait > 0) {
          this.waitTime = wait / 1000
          clearInterval(this.timeSetInterval)
          this.timeSetInterval = setInterval(() => {
            if (this.waitTime > 0) {
              this.waitTime = this.waitTime - 1
            }
          }, 1000)
        }
      })
    },
    beforeReceive(opporId) {
      console.log('领取之前')
      this.receiveOpportunityId = opporId;
      if (this.receive.enableVerify && this.receive.enableVerify === true) {
        console.log('获取验证码')
        this.enableVerifyDialog = true;
        receiveApi.getCaptcha().then(res => {
          console.log(res)
          this.captchaSrc = window.URL.createObjectURL(res)
        })
      }
    },
    closeEnableVerifyDialog() {
      this.captchaSrc = ''
      this.captchaInput = ''
      this.receiveOpportunityId = ''
      this.enableVerifyDialog = false;
    },
    submitEnableVerifyDialog() {
      receiveApi.verifyCaptcha(this.captchaInput).then(res => {
        if (res.success) {
          this.receiveOppor(this.receiveOpportunityId, false)
          this.closeEnableVerifyDialog()
        } else {
          this.$message.error("请输入正确的验证码")
        }
      })
    },
    /**
     * 领取机会
     * @param e 触发事件
     * @param opporId 机会Id
     * @param type 类型 1 拨打 2 领取 3 冻结
     */
    receiveOppor(opporId, verify) {
      if (this.receive.enableVerify && verify) {
        this.beforeReceive(opporId)
        return;
      }
      if (!this.isShowGetBtn(opporId)) {
        this.$message.warning('禁止重复领取');
        return
      }

      receiveApi.receiveOppor(this.receiveId, opporId).then(res => {
        if (res.success) {
          this.showGetBtn.push(opporId)
          this.getReceiveSize()
          // this.findOppor()
          this.$message.success(res.msg)
        } else {
          this.$message.warning(res.msg);
        }
      }).catch(error => {
        this.$message.error("领取失败");
      })
    },

    /**
     * 冻结
     * @param e
     * @param id
     */
    frozen(id, freezeTime, params, scope) {
      api.frozen(id, freezeTime, 2, params).then(res => {
        if (res.success) {
          this.$message.success(res.msg)
          this.handleFrozenFormClose(scope)
        } else {
          this.$message.warning(res.msg);
        }
      }).catch(error => {
        this.$message.error("冻结失败");
      })
      scope._self.$refs[`frozen-${scope.$index}`].disabled = true
    },
    next() {
      if (this.stockTotal === 0) {
        this.$message.warning("没有更多数据了")
      } else {
        this.nextQuery.btnType = 2
        this.type === 1 ? this.findOppor2Time(this.nextQuery) : this.findOppor()
      }
      // console.log(this.nextQuery)
      // if(this.nextQuery !== null){
      //     this.findOppor2Time(this.nextQuery)
      // }
    },
    toHome(receiveId) {
      this.$store.dispatch('setHeaderTitle', "学习顾问页面")
      let params = {};
      if (receiveId) {
        params['receiveId'] = receiveId;
      }
      this.$router.back();
      // this.$router.push({
      //     name: 'Consultant',
      //     params: params
      // })
      // console.log(params)
    },
    /**
     *
     */
    handleCheckbox() {
      let shortcut = this.frozenForm.shortcut
      if (shortcut) {
        this.frozenForm.remark = shortcut.toString()
      } else {
        this.frozenForm.remark = ""
      }
    },
    /**
     * popover显示时触发
     */
    handlePopoverShow() {
      this.frozenForm = {
        shortcut: [],
        time: "",
        remark: ""
      }
    },
    /**
     * 冻结表单提交
     */
    handleFrozenFormSubmit(scope, opporId) {
      if (!this.frozenForm.time) {
        this.$message.warning("未选择冻结时间")
        return
      }
      if (!this.frozenForm.remark) {
        this.$message.warning("未填写冻结原因")
        return
      }
      if (this.frozenForm.remark.length > 15) {
        this.$message.warning("冻结原因字数不可超过15个字")
        return
      }

      this.frozenForm.opportunityId = opporId
      this.frozenForm.userId = getUserId()
      this.frozenForm.applicant = getUserName()
      this.frozen(opporId, this.frozenForm.time, this.frozenForm, scope)
    },
    handleFrozenFormClose(scope) {
      scope._self.$refs[`popover-${scope.$index}`].doClose()
    }

  },
  computed: {
    isShowGetBtn() {
      return (id) => {
        return this.showGetBtn.indexOf(id) === -1
      }
    }
  },
  mounted() {
    if (this.$route.params.pondId) {
      this.pondId = this.$route.params.pondId
    }
    this.receiveId = this.$route.params.id
    this.findOppor2Time({})
    this.Index()
    if (this.receiveId) {
      this.getReceive()
      this.getReceiveSize()
    }
  }

}
</script>

<style scoped lang="less">

.custom--top-search-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px;

  .search-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 20px;
  }

  .search-label {
    font-size: 12px;
    margin-right: 10px;
    width: 55px;
  }

  .search-input {
    width: 150px;
  }

  .search-btn {
    margin-left: 20px;
  }
}

.custom--top-btn-wrapper {
  .left-btn-group {
  }

  .right-btn-group {
    flex: 1;
    width: auto;
  }

  .custom--GgdCard {
    width: 160px;
  }
}


/deep/ .el-button--success {
  color: #FFF;
  background-color: #2f8e00;
  border-color: #2f8e00;
}

.frozen-button {
  margin-left: 10px;
}

/deep/ .el-radio {
  margin-right: 0;
}

.receive-font {
  margin: 0;
  padding: 0;
  line-height: 20px;
  font-size: 14px;
}

</style>
